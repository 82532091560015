import React from "react"
import { graphql } from "gatsby"
// import { Helmet } from 'react-helmet'

import { MDXRenderer } from "gatsby-plugin-mdx"

import Layout from '../components/layout';
import SEO from '../components/seo';

import "../styles/content.css"

// import { MetaData } from '../components/common/meta'

// just a default top-level page. nothing special, render it in the layout.
export default function Page({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { mdx } = data;  // data.mdx holds your post data
  const { frontmatter, body } = mdx;

  const seoComponent = <SEO title={frontmatter.title} description={frontmatter.description || mdx.excerpt}
     path={frontmatter.slug} generateImage={true}
  />

  return (
    <Layout title={frontmatter.title} seoComponent={seoComponent}>
      {/*<SEO type="article" title={frontmatter.title} description={frontmatter.description} imageUrl={frontmatter.imageUrl}/>*/}
      <div className="content-body markdown-body">
        <MDXRenderer>{body}</MDXRenderer>
      </div>
    </Layout>
  )
}
export const pageQuery = graphql`
  query($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      body
      excerpt
      frontmatter {
        # date(formatString: "MMMM DD, YYYY")
        slug
        title
        imageUrl
        description
      }
    }
  }
`